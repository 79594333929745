import nookies, { destroyCookie, parseCookies, setCookie } from "nookies";

// If env not development secure cookie
const APP_HOST_NAME = process.env.APP_HOST_NAME != 'localhost:3000';

// Set user cookie from client
export const setCookies = (data) => {
  const { jwt, user, maxAge } = data;
  setCookie(null, 'authToken', jwt, {
    maxAge: maxAge ? 10000000 : null,
    path: '/',
    sameSite: APP_HOST_NAME ? "none" : null,
    secure: APP_HOST_NAME
  });
  // saved in localstorage to be used in the open-call-view component
  localStorage.setItem('token', JSON.stringify(jwt));
  setCookie(null, "email", user.email, {
    maxAge: maxAge ? 10000000 : null,
    sameSite: APP_HOST_NAME ? "none" : null,
    secure: APP_HOST_NAME
  });
  // setCookie(null, "username", user.username, {
  //   maxAge: maxAge ? 10000000 : null,
  //   sameSite: APP_HOST_NAME ? "none" : null,
  //   secure: APP_HOST_NAME
  // });
}

export const setAnalyticsFlag = (flag) => {
  setCookie(null, 'analyticsFlag', flag, {
    maxAge: null,
    path: '/',
  })
}

export const getAnalyticsFlag = () => {
  const { analyticsFlag } = parseCookies();
  return analyticsFlag;
}

export const getAnalyticId = () => {
  const { ajs_user_id } = parseCookies();
  return ajs_user_id;
}

/*
export const removeCookies = (ctx = {}) => {
  destroyCookie(ctx, 'authToken');
  destroyCookie(ctx, 'email');
  destroyCookie(ctx, 'username');
  destroyCookie(ctx, 'essentialData');
  destroyCookie(ctx, 'analyticsFlag');
}
*/

export const removeCookies = (ctx) => {
  destroyCookie(ctx, 'authToken');
  destroyCookie(ctx, 'email');
  destroyCookie(ctx, 'username');
  destroyCookie(ctx, 'essentialData');
  destroyCookie(ctx, 'analyticsFlag');
}

export const removeCookiesFromClient = () => {
  destroyCookie(null, 'authToken', { path: '/', });
  destroyCookie(null, 'email', { path: '/', });
  destroyCookie(null, 'username', { path: '/', });
  destroyCookie(null, 'essentialData', { path: '/', });
  destroyCookie(null, 'analyticsFlag', { path: '/', });
}


export const getCurrentUserCookieFromServer = (ctx) => {
  const cookies = nookies.get(ctx);
  return {
    username: cookies.username, email: cookies.email
  };
}

export const getJwtToken = (ctx) => {
  const cookies = nookies.get(ctx);
  return cookies.authToken;
}

export const getJwtTokenFromClient = () => {
  const { authToken } = parseCookies();
  return { authToken };
}

// TODO: Redo against a backend check
export const authCheck = (ctx) => {
  if (!getJwtToken(ctx)) {
    ctx.res.writeHead(303, { Location: "/login" });
    ctx.res.end();
  }
}

export const isUserLoggedInFromClientSide = () => {
  const { authToken } = parseCookies();
  return !!authToken;
}

export const getUserFromClientSide = () => {
  const { username, email } = parseCookies();
  return { username, email };
}

export const setEssentialDataCookie = (essentialData) => {
  setCookie(null, 'essentialData', essentialData, {
    path: '/',
    sameSite: APP_HOST_NAME ? "none" : null,
    secure: APP_HOST_NAME
  });
}

export const getEssentialDataCookie = () => {
  const { essentialData } = parseCookies();
  return essentialData;
}
